@import './../../../../config.scss';

.container {
    background-color: $bg-aboutus-location;

    .wrapper {
        min-height: 350px;
        padding: 60px 20px;
        margin: 0px auto;
        max-width: $max-width;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        align-content: center;
        gap: 80px;
    }

    .pin {
        display: flex;
        gap: 10px;

        .icon {
            color: $primary-color;
        }

        .text {
            margin: 0px;
        }
    }

    .office {
        width: 50%;
        
        .address {
            width: 100%;
            align-items: center;
            display: flex;

            .header {
                color: $white-color;
                font-size: 20px;
                font-weight: 600;
            }

            .text, .link {
                display: block;
                color: $white-color;
                font-size: 14px;
            }

            .link {
                margin-bottom: 10px;
            }
        }

        .map {
            height: 200px;
            width: 100%;
        }
    }

    .openInMaps {
      color: $white-color;
      font-size: 12px;
    }
}

@media (max-width: 720px) {
    .container {
        .wrapper {
            display: flex;
            flex-direction: column;
            gap: 40px;
            align-items: flex-start;
            padding: 20px 20px 40px;
        }

        .office {
            width: 100%;

            .address {
                max-width: unset;
                align-items: flex-start;
                justify-content: center;
            }
            
            .text, .openInMaps, .header {
                text-align: center;
                display: block;
            }

            .map {
                height: 300px;
            }
        }
    }
}