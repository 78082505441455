@import '../../../../config.scss';

.container {
  height: 250px;
  display: flex;
  flex-direction: row;

  .sideLeft, .sideRight {
    width: 50vw;
    position: relative;
  }

  .overlay {
    opacity: 0.8;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: calc(($max-width / 2) - 40px);
    position: absolute;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    z-index: 1;
    margin: 20px;
  }

  .sideLeft .content {
    right: 0px;
  }

  .text {
    color: $white-color;
    font-family: "franklin-gothic-atf", "metropolis", sans-serif;
    font-size: 40px;
    font-weight: 700;
    margin: 0px;
    text-transform: uppercase;
  }

  .number {
    color: $white-color;
    font-family: "franklin-gothic-atf", "metropolis", sans-serif;
    font-size: 28px;
    font-weight: 700;
    margin: 0px;
  }
}

.sideLeft {
  background-image: url('../../../../assets/images/home-services-commercial.jpg');
  background-size: cover;
  background-position: center;

  .overlay {
    background-color: $bg-home-commercial;
  }
}

.sideRight {
  background-image: url('../../../../assets/images/home-services-residential.jpg');
  background-size: cover;
  background-position: center;

  .overlay {
    background-color: $bg-home-residential;
  }
}

@media (max-width: 720px) {
  .container {
    height: unset;
    flex-direction: column;

    .sideLeft, .sideRight {
      height: 250px;
      width: 100%;
      position: relative;

      .content {
        left: 0px;
      }
    }
  }
}

/*.sideRight,
.sideLeft {
  min-width: 50%;
  min-height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}*/

/*.content1,
.content2 {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  position: absolute;
  z-index: 1;
}

.content1 {
  background-color: #1b5e20;
}

.content2 {
  background-color: #0d47a1;
}

.text {
  z-index: +1;
  color: white;
  position: absolute;
  font-size: calc(var(--size) * 6);
  text-transform: uppercase;
  font-weight: 900;
}
*/