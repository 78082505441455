@import './../../../../config.scss';

.container {
    justify-content: center;
    background-color: $bg-aboutus-aboutus;

    h2 {
        text-transform: uppercase;
        font-family: "franklin-gothic-atf", "metropolis", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        text-align:center;
        color: $primary-color;
        margin: 0px;
        padding: 40px 20px 20px;
    }

    .tagsContainer {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      margin: 10px 0px;
  
      .tag {
        background-color: $primary-color;
        color: $white-color;
        text-transform: uppercase;
        padding: 5px 10px 4px;
        border-radius: $border-radius;
        font-size: 10px;
      }
    }

    .wrapper {
        padding: 40px 20px;
        margin: 0px auto;
        max-width: $max-width;
        display: flex;
        gap: 100px;
    }

    .image {
        width: 100%;

        img {
            width: 100%;
            object-fit: cover;
            max-height: 400px;
            border-radius: $border-radius;
        }
    }

    .vision {
        width: 100%;
        flex-grow: 1;
        max-width: 600px;
    }

    .section {
        margin-bottom: 20px;

        h3 {
            margin: 0px;
            margin-bottom: 8px;
        }

        .text {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

@media (max-width: 720px) {
    .container {
        .wrapper {
            flex-direction: column-reverse;
            gap: 20px;
        }

        h2 {
            text-align: left;
        }

        .image img {
            height: 200px;
        }
    }
}