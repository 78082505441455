@import '../../../../config.scss';

.container {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  background-color: $bg-home-portfolio;

  .wrapper {
    width: 100%;
    max-width: $max-width;
    margin: 0px auto;
  }

  .header {
    padding: 40px;

    .sub {
      font-family: "metropolis-thin", sans-serif;
      font-style: normal;
      font-size: 16px;
      margin: 0px;
    }

    .main {
      text-transform: uppercase;
      font-family: "franklin-gothic-atf", "metropolis", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
    }
  }

  .link {
    margin: 40px auto;
    max-width: $max-width;
    text-align: right;
    width: 100%;
  }

  .projectsContainer {
    background-color: $primary-color;
    width: 100%;
    max-width: $large-max-width;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px auto;

    .project {
      position: relative;
      cursor: pointer;
      width: 25%;
      height: 200px;

      .noImage {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ddd;
      }

      .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        opacity: 0.8;
      }

      &:hover .overlay {
        height: 100%;
      }

      &:hover .content {
        opacity: 1;
      }
      
      .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $primary-color;
        opacity: 0.8;
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: 0.5s height ease;
      }

      .content {
        position: absolute;
        opacity: 0;
        bottom: 0px;
        padding: 10px;
        z-index: 1;
        transition: 0.5s opacity ease;
        font-size: 20px;

        .text {
          color: $white-color;
          font-family: "metropolis", sans-serif;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .container {
    .projectsContainer {
      .project {
        width: 50%;
      }
    }

    .link {
      width: calc(100% - 40px);
    }
  }
}

/*.text1,
.text2 {
  color: var(--primary);
  text-transform: uppercase;
}

.text1 {
  font-size: calc(var(--size) * 1.5);
  font-weight: 700;
}

.text2 {
  font-size: calc(var(--size) * 2);
  font-weight: 700;
}

.textContainer {
  display: flex;
  flex-direction: column;
  padding: var(--padding-section);
}

.projectsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.projectImage {
  width: 200px;
}

.project {
  position: relative;
  cursor: pointer;
}

.project:hover .overlay {
  height: 100%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--black);
  opacity: 0.5;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.overlayContent {
  position: absolute;
  z-index: 1;
}

.textOverlay {
  color: var(--white);
}
*/