$max-width: 1240px;
$large-max-width: 1920px;

$primary-color: #162238;
$primary-darker-color: #04132f;
$secondary-color: #f1f2ed;
$tertiary-color: #e7ebf0;
$white-color: #fff;

$bg-home-banner: #fff;
$bg-home-clients: #d8d8d8;
$bg-home-aboutus: #f0f1ee;
$bg-home-commercial: #152238;
$bg-home-residential: #074b39;
$bg-home-portfolio: #e7eaf0;

$bg-aboutus-aboutus: #f0f1ee;
$bg-aboutus-location: $primary-color;

$bg-component-contact: #f0f1ee;

$border-radius: 4px;
$box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.11);

@font-face { font-family: metropolis-thin; src: url('./assets/fonts/metropolis/Metropolis-Thin.otf'); } 
@font-face { font-family: metropolis-light; src: url('./assets/fonts/metropolis/Metropolis-Light.otf'); } 
@font-face { font-family: metropolis; src: url('./assets/fonts/metropolis/Metropolis-Regular.otf'); } 
@font-face { font-family: metropolis-medium; src: url('./assets/fonts/metropolis/Metropolis-Medium.otf'); } 

body {
    padding: 0px;
    margin: 0px;
    font-family: 'metropolis', 'franklin-gothic-atf', sans-serif;
}

h1, h2, h3 {
}

a {
  color: $primary-color;
  text-decoration: none;

  &:hover {
    color: $primary-darker-color;
    text-decoration: underline;
  }
}