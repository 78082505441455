@import '../../../../config.scss';

.container {
  padding: 20px;
  height: 400px;

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: $max-width;
    margin: 0px auto;
    width: 100%;
    height: 100%;
    gap: 40px;

    .header {
      text-transform: uppercase;
      font-family: 'franklin-gothic-atf', 'metropolis', sans-serif;
      font-style: normal;


      .sub {
        font-weight: 600;
        font-size: 24px;
        margin: 0px;
      }

      .main {
        font-weight: 700;
        font-size: 40px;
        margin: 0px;
        margin-top: 0px;
      }
    }

    .leftContainer {
      width: 50%;

      & > * {
        margin-bottom: 10px;
      }

      .btnContact {
        background-color: $white-color;
        color: $primary-color;
        border: 2px solid $primary-color;
        padding: 8px 20px;
        text-decoration: none;
        border-radius: $border-radius;
        text-transform: uppercase;
        margin-top: 10px;

        -webkit-transition: background-color 0.3s linear;
        -ms-transition: background-color 0.3s linear;
        transition: background-color 0.3s linear;

        &:hover {
          background-color: $primary-color;
          color: $white-color;
          cursor: pointer;
        }
      }
    }

    .imageContainer {
      background: url('./../../../../assets/images/home-banner.jpg');
      height: 300px;
      width: 50%;
      background-size: cover;
      background-position: center;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
    }
  }
}

.clip {
  clip-path: polygon(0 0, 100% 0, 100% 50%);
  height: 600px;
  background-color: $primary-color;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  top: 80px;
  z-index: -1;
}

@media (max-width: 720px) {
    .container {
      height: unset;

      .wrapper {
        gap: 10px;
        height: unset;
        flex-direction: column-reverse;

        .leftContainer,
        .imageContainer {
          width: 100%;
        }

        .imageContainer {
          height: 200px;
        }
      }
    }
}