@import './../../config.scss';

.container {
  align-items: center;
  display: flex;
  padding: 10px;
  height: 60px;
  background-color: $primary-color;

  .mobileLink {
    display: none;
  }

  .wrapper {
    margin: 0px auto;
    width: 100%;
    max-width: $max-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 60px;
    }
  }

  .linkContainer {
    display: flex;

    .link {
      font-family: 'metropolis-light', 'franklin-gothic-atf', sans-serif;
      color: $white-color;
      font-size: 16px;
      text-transform: uppercase;
      padding: 10px 20px;
      margin: 0px 5px;
      border-radius: $border-radius;
      text-decoration: none;

      -webkit-transition: background-color 0.3s linear;
      -ms-transition: background-color 0.3s linear;
      transition: background-color 0.3s linear;

      &:hover {
        background-color: $white-color;
        color: $primary-color;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 720px) {
  .container {
    .mobileLink {
      display: flex;

      .menuButton {
        background-color: $white-color;
        border: 1px solid $white-color;
        outline: none;
        border-radius: $border-radius;
        color: $primary-color;
        padding: 8px 12px 6px;

        &:hover {
          cursor: pointer;
          background-color: $primary-color;
          color: $white-color;
        }
      }
    }

    .logoContainer img {
      height: 40px;
    }

    .linkContainer {
      display: none;
    }

    .menuPanel {
      position: fixed;
      top: 0px;
      bottom: 0px;
      width: 100vw;
      right: -100vw;
      height: 100vh;
      -webkit-transition: right 0.2s linear;
      -ms-transition: right 0.2s linear;
      transition: right 0.2s linear;
      z-index: 999;
      background-color: $white-color;

      .closePanelButton:hover {
        cursor: pointer;
      }

      &.expanded {
        right: 0px;
      }

      .mobileLinkContainer {
        display: flex;
        flex-direction: column;
        margin: 20px;
        gap: 15px;

        .link {
          font-family: 'metropolis', 'franklin-gothic-atf', sans-serif;
          color: $primary-color;
          font-size: 16px;
          text-transform: uppercase;
          padding: 15px 20px;
          margin: 0px 5px;
          border-radius: $border-radius;
          text-decoration: none;

          -webkit-transition: background-color 0.3s linear;
          -ms-transition: background-color 0.3s linear;
          transition: background-color 0.3s linear;
    
          &:hover {
            background-color: $primary-color;
            color: $white-color;
            cursor: pointer;
          }
        }
      }

      .panelHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        padding: 20px;
        margin: 0px 20px;

        button {
          background-color: transparent;
          color: $primary-color;
          height: 40px;
          width: 40px;
          border: 0px;
          outline: none;
        }

        img {
          height: 50px;
          object-fit: contain;
        }
      }
    }
  }
}
