@import '../../../../config.scss';

.container {
  background-color: $bg-home-clients;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wrapper {
    padding: 100px 0px;
    max-width: $max-width;
    margin: 0px auto;
  }

  .header {
    text-align: center;
    text-transform: uppercase;
    font-family: 'franklin-gothic-atf', 'metropolis', sans-serif;
    font-style: normal;
    font-size: 32px;
    margin-bottom: 40px;
  }

  .logoContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .clientLogo {
      padding: 20px;
      margin: 0px 30px;

      img {
        max-height: 70px;
      }
    }
  }
}


@media (max-width: 720px) {
  .container {
    .wrapper {
      padding: 20px 0px;

      .logoContainer .clientLogo {
        margin: 0px;

        img {
          max-height: 50px;
        }
      }
    }
  }
}