@import './../../config.scss';

.container {
    background-color: $bg-component-contact;

    & > .wrapper {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        width: 100%;
        max-width: $large-max-width;
        margin: 0px auto;
    }

    .leftContainer {
        width: 40%;
        position: relative;
        background: url('./../../assets/images/contact.jpg');
        background-size: cover;
        background-position: right;

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.3;
        }
    }

    .rightContainer {
        width: 60%;
        padding: 40px;

        .wrapper {
            max-width: calc($max-width / 2);
            margin: 0px auto;
        }
    }

    .success {
        color: rgb(1, 91, 1);
    }

    .error {
        color: rgb(123, 0, 0);
    }

    .header {
      text-transform: uppercase;
      font-family: "franklin-gothic-atf", "metropolis", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      margin-bottom: 42px;
    }

    .inputContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        label {
            margin-bottom: 6px;

            &::after {
                content: '*';
                color: red;
                margin-left: 2px;
            }
        }

        input {
            min-height: 30px;
            font-family: "metropolis", sans-serif;
            font-size: 14px;
            padding: 10px;
            border: 1px solid #000;
            border-radius: $border-radius;
        }

        textarea {
            min-height: 30px;
            font-family: "metropolis", sans-serif;
            font-size: 14px;
            padding: 10px;
            border: 1px solid #000;
            border-radius: $border-radius;
        }

        button {
            font-family: "metropolis", sans-serif;
            font-size: 14px;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            color: $white-color;
            padding: 10px 20px;
            min-height: 30px;
            border-radius: $border-radius;
            width: auto;
            opacity: 0.9;
            transition: 0.5s opacity ease;

            &:hover {
                opacity: 1;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 720px) {
    .container {
        .leftContainer {
            display: none;
        }

        .rightContainer {
            width: 100%;
        }
    }
}