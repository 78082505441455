@import './../../config.scss';

.container {
    min-height: calc(100vh - 260px);

    h2 {
        text-transform: uppercase;
        font-family: "franklin-gothic-atf", "metropolis", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        text-align:center;
        color: $primary-color;
        margin: 0px;
        padding: 40px 20px 20px;
    }
}

.projectRoot {
    max-width: $max-width;
    margin: 0px auto;
    padding: 20px 20px 40px;
    gap: 40px;
    
    .projectContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .project {
            position: relative;
            box-shadow: $box-shadow;
            width: calc(25% - 15px);
            margin: 0px 20px 20px 0px;

            &:nth-child(4n) {
                margin-right: 0px;
            }

            .image {
                border-radius: $border-radius;
                background-color: #ddd;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    border-radius: $border-radius;
                    width: 100%;
                    height: 150px;
                    object-fit: cover;
                }
            }

            &:hover .overlay {
                height: 100%;
            }

            &:hover .content {
                opacity: 1;
            }
            
            .overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $primary-color;
                opacity: 0.8;
                overflow: hidden;
                width: 100%;
                height: 0;
                transition: 0.5s height ease;
                border-radius: $border-radius;
            }

            .content {
                position: absolute;
                opacity: 0;
                bottom: 0px;
                padding: 10px;
                z-index: 1;
                transition: 0.5s opacity ease;
                font-size: 20px;

                .text {
                color: $white-color;
                font-family: "metropolis", sans-serif;
                }
            }
        }
    }

    .projectText {
        margin-bottom: 10px;
    }
}

@media (max-width: 920px) {
    .projectRoot {
        .projectContainer {
            .project {
                width: calc(33.3% - 20px);

                &:nth-child(4n) {
                    margin-right: 20px;
                }

                &:nth-child(3n) {
                    margin-right: 0px;
                }
            }
        }
    }
}

@media (max-width: 720px) {
    .projectRoot {
        .projectContainer {
            .project {
                width: calc(50% - 10px);

                &:nth-child(3n) {
                    margin-right: 20px;
                }

                &:nth-child(2n) {
                    margin-right: 0px;
                }
            }
        }
    }
}