@import '../../../../config.scss';

.container {
  background-color: $bg-home-aboutus;

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: $max-width;
    margin: 0px auto;
    padding: 40px;
    min-height: 50vh;
    gap: 40px;
  }

  .leftContainer {
    width: 100%;
    max-width: 500px;
  }

  .header {
    .sub {
      font-family: "metropolis-thin", sans-serif;
      font-style: normal;
      font-size: 16px;
      margin: 0px;
    }

    .main {
      text-transform: uppercase;
      font-family: "franklin-gothic-atf", "metropolis", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
    }
  }

  .content {
    margin-top: 20px;

    .text {
      font-family: "metropolis-light", sans-serif;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .tagsContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;

    .tag {
      background-color: $primary-color;
      color: $white-color;
      text-transform: uppercase;
      padding: 5px 10px 4px;
      border-radius: $border-radius;
      font-size: 10px;
    }
  }

  .readMore {
    margin-top: 30px;
    font-size: 14px;
  }

  .imageContainer {
    position: relative;
    width: 100%;
    height: 300px;

    .image1 {
      position: absolute;
      border-radius: $border-radius;
      height: 200px;
      top: 0px;
      left: 0px;
      width: 60%;
      background: url('./../../../../assets/images/home-aboutus.jpg');
      background-size: cover;
      background-position: center;
    }

    .image2 {
      position: absolute;
      border-radius: $border-radius;
      height: 200px;
      bottom: 0px;
      right: 0px;
      width: 60%;
      background: url('./../../../../assets/images/home-aboutus-2.jpg');
      background-size: cover;
      background-position: center;
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  max-width: calc(var(--size) * 40);
}

.text1,
.text2,
.text3 {
  color: var(--primary);
  text-transform: uppercase;
}

.text1 {
  font-size: calc(var(--size) * 2);
  font-weight: 700;
}
.text2 {
  font-size: calc(var(--size) * 1.5);
  font-weight: 700;
}

@media (max-width: 720px) {
  .container {
    .wrapper {
      min-height: unset;
      flex-direction: column;

      .imageContainer {
        height: 200px;
      }

      .image1 {
        width: 100%;
      }
      
      .image2 {
        display: none;
      }
    }
  }
}