@import './../config.scss';

.floatingWhatsapp {
    position: fixed;
    right: 30px;
    bottom: 30px;
    background-color: #29d448;
    color: #fff;
    border-radius: 50%;
    font-size: 40px;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    svg {
        position: relative;
        left: 1px;
    }

    &:hover {
        color: $white-color;
        background-color: #2abd45;
    }
}

@media (max-width: 720px) {
    .floatingWhatsapp {
        right: 20px;
        bottom: 20px;
    }   
}