@import './../../config.scss';

.container {
  padding: 20px;
  background-color: $primary-color;

  .wrapper {
    margin: 20px auto;
    width: 100%;
    max-width: $max-width;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }

  .rightContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .address {
      max-width: 300px;
    }
  }

  .header {
    color: $white-color;
    font-weight: 500;
    margin: 0px;
    font-size: 20px;
  }

  .text, .link {
    display: block;
    color: $white-color;
    font-family: 'metropolis-thin', 'metropolis', sans-serif;
    margin: 10px 0px 0px;
    font-size: 14px;
    line-height: 20px;
  }

  .logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 60px;
    }
  }

  .openInMaps {
    color: $white-color;
    font-size: 12px;
  }
}

@media (max-width: 720px) {
  .container {
    .wrapper {
      align-items: center;
      flex-direction: column;
      gap: 40px;

      .rightContainer {
        flex-direction: column;
        align-items: center;
        gap: 40px;
      }

      .header, .text, .link {
        text-align: center;
      }

      .openInMaps {
        display: block;
        text-align: center;
      }
    }
  }
}