:root {
  /* sizes */
  --size: 10px;
  --section-size: 50vh;
  --padding-section: 0 20%;

  /* colors */
  --primary: #162238;
  --gray-3: #f1f2ed;
  --gray-2: #e7ebf0;
  --gray-1: #d8d8d8;
  --white: #ffffff;
  --black: #000000;
}

@media screen and (min-width: 620px) {
  :root {
    --size: 8px;
  }
}
